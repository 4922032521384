import React, { Component } from "react"
import LayoutHome from "../components/LayoutHome"
import { graphql } from "gatsby"
import BasicText from "../components/BasicText"
import Slider from "../components/Slider"
import css from "./stylesheets/index.module.scss"
import CommonButton from "../components/CommonButton"
import Seo from "../components/Seo"
import AnimatedLogo from "../components/AnimatedLogo"
import ScrollDown from "../components/ScrollDown"
import Img from "gatsby-image"
import IconFb from "../images/icon_fb.svg"

class PageIndex extends Component {
  constructor(props) {
    super(props)
    this.doClickLangButton = this.doClickLangButton.bind(this)
  }

  doClickLangButton() {
    let layoutBasic = this.ref.layoutBasicInstance
    if (typeof layoutBasic !== "undefined" && layoutBasic !== null) {
      layoutBasic.ref.globalNavigationInstance.openLanguageMenu()
    }
  }

  render() {
    let srcList = []
    for (let i = 0; i < 4; i += 1) {
      srcList.push([
        this.props.data["slideSpImage" + (i + 1)].childImageSharp.fluid,
        {
          ...this.props.data["slideImage" + (i + 1)].childImageSharp.fluid,
          media: `(min-width: 835px)`,
        },
      ])
    }

    this.ref = {}

    return (
      <div className={css.topPage}>
        <Seo title={`HOME`} lang={`en`} />
        <LayoutHome reference={this.ref} lang={`en`}>
          <Slider intro={true}>
            <Img
              fluid={srcList[0]}
              objectFit="cover"
              objectPosition="50% 50%"
              alt=""
              style={{ width: "100%", height: "100%" }}
            />
            <Img
              fluid={srcList[1]}
              objectFit="cover"
              objectPosition="50% 50%"
              alt=""
              style={{ width: "100%", height: "100%" }}
            />
            <Img
              fluid={srcList[2]}
              objectFit="cover"
              objectPosition="50% 50%"
              alt=""
              style={{ width: "100%", height: "100%" }}
            />
            <Img
              fluid={srcList[3]}
              objectFit="cover"
              objectPosition="50% 50%"
              alt=""
              style={{ width: "100%", height: "100%" }}
            />
          </Slider>
          <div>
            <section className={css.intro}>
              <h1>
                <AnimatedLogo />
              </h1>
              <ScrollDown
                style={{
                  position: "absolute",
                  bottom: "2vh",
                }}
                to={`#TopContent`}
              />
            </section>
            <BasicText>
              <p>
                <a
                  className={
                    css.facebookLink + ` withoutBlankIcon withoutAnimation`
                  }
                  href={this.props.data.site.siteMetadata.facebookLink}
                  target={`_blank`}
                >
                  <img src={IconFb} alt="facebook" />
                  <span>The latest news on our Facebook page</span>
                </a>
              </p>

              <hr />

              <h2 id={`TopContent`}>WHAT is KAIGO?</h2>
              <p>
                Welcome to our website!
                <br />
                ‘KAIGO in JAPAN’ is a website for those who are planning to work
                in the care work field in Japan. We are willing to help you with
                your questions about nursing care, and life in Japan. We also
                have reports from those who already started their career in
                Japan.
                <br />
                The contents include the interviews of the workers, the basic
                information about Japan and nursing care, and FAQs.
                <br />
                You can select your language from ‘Easy Japanese,’ ‘Japanese’
                and ‘English.’
                <br />
                We hope this website will help you to get to know more about
                nursing care in Japan.
              </p>
              <p className={`commonButton`}>
                <button onClick={this.doClickLangButton}>
                  <div>
                    <span>LANGUAGE</span>
                  </div>
                </button>
              </p>
              <h3 className={css.Heading3h}>
                Our Life in a Nursing Care Facility <small>(3:47)</small>
              </h3>

              <div className={`fixedRatioBox is-16x9`}>
                <iframe
                  src="https://www.youtube.com/embed/i8hNvS-DbTk"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Our Life in a Nursing Care Facility"
                ></iframe>
              </div>

              <CommonButton to={`/en/what-is-kaigo/`}>MORE</CommonButton>

              <hr />

              <h2>INTERVIEW</h2>
              <p>
                These are the interviews with those who are working as care
                workers in Japan. We interviewed care workers who had a variety
                of residence statuses. They are working in relationship pairs,
                such as, senior and junior, coworkers, and husband and wife. We
                visited their workplaces and accommodations.
              </p>
              <CommonButton to={`/en/interview/`}>MORE</CommonButton>

              <hr />

              <h2>BASIC INFORMATION</h2>
              <p>
                What’s Japan like? How to get a job in the nursing care field in
                Japan?
                <br />
                Let us introduce the basic information about Japan and four
                different types of status of residence to work as a care worker.
              </p>
              <CommonButton to={`/en/basic-information/`}>MORE</CommonButton>

              <hr />

              <h2>FAQ</h2>
              <p>
                These are the frequently asked questions. This section
                introduces some useful organizations and services.
              </p>
              <CommonButton to={`/en/faq/`}>MORE</CommonButton>
            </BasicText>
          </div>
        </LayoutHome>
      </div>
    )
  }
}

export default PageIndex

export const query = graphql`
  query {
    site {
      siteMetadata {
        facebookLink
      }
    }
    slideImage1: file(relativePath: { eq: "slider/01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    slideSpImage1: file(relativePath: { eq: "slider/01_sp.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 750, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    slideImage2: file(relativePath: { eq: "slider/02.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    slideSpImage2: file(relativePath: { eq: "slider/02_sp.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 750, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    slideImage3: file(relativePath: { eq: "slider/04.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    slideSpImage3: file(relativePath: { eq: "slider/04_sp.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 750, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    slideImage4: file(relativePath: { eq: "slider/05.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    slideSpImage4: file(relativePath: { eq: "slider/05_sp.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 750, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
