import React from "react"
import { Link } from "gatsby"

export default props => {
  let styles = {}

  if (props.align) {
    styles.textAlign = props.align
  }

  return (
    <p className={`commonButton`} style={styles}>
      <Link to={props.to}><span>{props.children}</span></Link>
    </p>
  )
}
