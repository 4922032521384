import React, { Component } from "react"
import IconScroll from "../images/icon-scroll-down.svg"
import css from "./stylesheets/ScrollDown.module.scss"
import throttle from "lodash.throttle"
import AnchorScroll from "../utils/AnchorScroll"

class ScrollDown extends Component {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.handleScroll = this.handleScroll.bind(this)

    this.state = {
      scrolled: false,
    }
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", this.handleScroll)
    }
  }

  componentWillUnmount() {
    if (typeof window !== "undefined") {
      window.removeEventListener("scroll", this.handleScroll)
    }
  }

  handleScroll = throttle(e => {
    if (typeof window !== "undefined") {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop
      this.setState(state => ({
        scrolled: scrollTop > 80,
      }))
    }
  }, 50)

  handleClick(e) {
    console.log(this.props.to)
    AnchorScroll.doScrollDown(this.props.to, 700)
    if (e) e.preventDefault()
  }

  render() {
    let className = ""
    if (this.state.scrolled) {
      className = css.baseIsScrolled
    }
    if (this.props.className) {
      className += " " + this.props.className
    }

    return (
      <a
        href={this.props.to}
        onClick={this.handleClick}
        className={css.base + ` ` + className}
        style={this.props.style}
      >
        <span>SCROLL</span>
        <img src={IconScroll} alt="" />
      </a>
    )
  }
}
export default ScrollDown
