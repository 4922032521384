import React, { Component } from "react"
import "./stylesheets/AnimatedLogo.scss"

class AnimatedLogo extends Component {
  // constructor(props) {
  //   super(props)
  // }

  render() {
    let styleColor = {
      fill: `#ef0012`,
    }

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 216.26 84.8"
        width={"216"}
        height={"84"}
      >
        <title>KAIGO IN JAPAN</title>
        <g id="レイヤー_2" data-name="レイヤー 2">
          <g id="l">
            <circle
              id="楕円形_8"
              data-name="楕円形 8"
              className={`logoAnimCircle`}
              style={styleColor}
              cx="194.24"
              cy="71.97"
              r="6.87"
            />
            <path
              id="パス_13"
              data-name="パス 13"
              style={styleColor}
              className={`logoAnimKaigo`}
              d="M27.9,17.52,42.22.75h-16L22.4,5.57c-3,3.87-7.2,9.37-9.16,12.49.13-3.46.2-7.74.2-11.74V.75H0V48.26H13.44V33.94L18.33,28l9.9,20.22H43.85Zm39,11.94.88-3.12c1.7-6,2.45-8.69,3.06-11.88.61,3.19,1.35,5.77,3,11.88l.88,3.12ZM77.51.75H64.21L47,48.27H61.09l2.58-8.35H77.92l2.58,8.35H94.76Zm37.81,0H101.88V48.27h13.44ZM166.5,22.2H145.6V32h8.61c-.54,3.26-2.37,5.84-7.94,5.84-6.11,0-9.16-3.94-9.16-13.37,0-7.54,2.1-13,8.28-13,4.55,0,6.45,2.45,8,6.52l11.95-4.68C162,4.42,157.34,0,145.46,0c-14.39,0-22.13,10.45-22.13,24.5,0,15.54,8.89,24.5,21.92,24.5C159,49,166.5,41.54,166.5,28ZM194.33,0c-12.9,0-22.06,8.76-22.06,24.57S181.3,49,194.2,49s22.06-8.76,22.06-24.57S207.23,0,194.33,0Zm0,37.54c-5.29,0-8.42-4.55-8.42-13.1s3-13,8.29-13,8.41,4.55,8.41,13.1-3,13-8.28,13Z"
            />
            <path
              id="パス_14"
              data-name="パス 14"
              style={styleColor}
              className={`logoAnimJapan`}
              d="M60.29,59.53h-7V74.82c0,2.38-1.1,3.91-3.3,3.91-2.38,0-3.34-1.46-4.2-4.3L39,76.2c1.28,5.23,4.23,8.6,10.84,8.6,7,0,10.45-4,10.45-10.52Zm12.62,15,.46-1.64c.89-3.16,1.28-4.55,1.6-6.22.32,1.68.71,3,1.6,6.22L77,74.57Zm5.58-15h-7l-9,24.88h7.39L71.24,80h7.47l1.35,4.38h7.46Zm23.28,0H91.07V84.41h6.79V76.35h3.45c6,0,10.16-2.24,10.16-8.57,0-6-3.87-8.25-9.7-8.25Zm-.28,11.2H97.86V65.15h3.73c2.2,0,3,1.1,3,2.74,0,1.88-.88,2.84-3.12,2.84Zm20,3.84.46-1.63c.89-3.16,1.28-4.55,1.6-6.22.32,1.67.71,3,1.6,6.22l.46,1.63Zm5.58-15h-7l-9,24.88h7.39l1.35-4.37h7.47l1.35,4.37h7.47Zm34.4,0h-6.61v6.79c0,2,0,5.33.22,6.43-.54-1-2.74-4.33-3.88-5.86l-5.47-7.36h-6.08V84.42h6.61V76.81c0-2,0-5.33-.21-6.43.64,1.1,2.77,4.3,3.87,5.83l5.94,8.21h5.61Z"
            />
            <g className={`logoAnimIn`}>
              <path
                style={styleColor}
                d="M4.54,58.15c-2.1,0-3.66,1.28-3.66,3.09s1.57,3.17,3.66,3.17,3.74-1.21,3.74-3.17S6.75,58.15,4.54,58.15Z"
              />
              <rect
                style={styleColor}
                x="1.34"
                y="65.75"
                width="6.4"
                height="18.66"
              />
              <path
                style={styleColor}
                d="M29,73c0-4.9-2-7.64-6-7.64A7.16,7.16,0,0,0,18.3,67V65.75H11.9V84.41h6.4V73.75c0-2.34.82-3,2.17-3s2.13.64,2.13,3v10.6H29Z"
              />
            </g>
            <path
              className={`logoAnimFrag`}
              id="パス_131"
              data-name="パス 131"
              style={styleColor}
              d="M213.25,60.54V83.41h-38V60.54h38m1-1h-40V84.42h40Z"
            />
          </g>
        </g>
      </svg>
    )
  }
}

export default AnimatedLogo
