import React, { Component } from "react"
import css from "./stylesheets/Slider.module.scss"

class Slider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      count: -1,
    }
  }

  componentDidMount() {
    let slideTime = this.props.slideTime ? this.props.slideTime : 7000
    this.intervalId = setInterval(() => {
      this.countUpdate()
    }, slideTime)

    setTimeout(() => {
      this.countUpdate()
    }, 0)
  }

  componentWillUnmount() {
    clearInterval(this.intervalId)
  }

  countUpdate() {
    let newCount = this.state.count + 1
    if (newCount > this.props.children.length - 1) {
      newCount = 0
    }
    this.setState(state => ({
      count: newCount,
    }))
  }

  render() {
    let list = []
    let theNextCount = this.state.count + 1
    if (theNextCount >= this.props.children.length) {
      theNextCount = 0
    }
    for (let i = 0; i < this.props.children.length; i += 1) {
      let sliderItemIsAnimated = css.sliderItemIsAnimated
      if(theNextCount===i){
        sliderItemIsAnimated = ""
      }


      if (this.state.count === i) {
        list.push(
          <div
            className={
              css.sliderItem +
              " " +
              css.sliderItemIsCurrent +
              " " +
              sliderItemIsAnimated
            }
            key={`Slider` + i}
          >
            {this.props.children[i]}
          </div>
        )
      } else {
        list.push(
          <div className={css.sliderItem +` `+sliderItemIsAnimated} key={`Slider` + i}>
            {this.props.children[i]}
          </div>
        )
      }
    }

    let cover = ""
    if (this.props.intro) {
      cover = <div className={css.cover}></div>
    }

    return (
      <div className={css.slider}>
        {cover}
        {list}
      </div>
    )
  }
}

export default Slider
