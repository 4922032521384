import React, { Component } from "react"
import GlobalNavigation from "./GlobalNavigation"
import css from "./stylesheets/LayoutHome.module.scss"
import GlobalFooter from "./GlobalFooter"
import AdobeTypekit from "./AdobeTypekit"
import ScrollDown from "./ScrollDown"

class LayoutHome extends Component {
  constructor(props) {
    super(props)
    this.node = React.createRef()
    this.rightColumnNode = React.createRef()
    this.ref = {}
    this._spChecker = React.createRef()
    this._leftSlideColumn = React.createRef()
    this.state = {
      isSpMode: false,
    }
  }

  componentDidMount() {
    // 親コンポーネントの参照を受け取る
    if (this.props.reference) {
      this.props.reference.layoutBasicInstance = this
    }

    // Webfont を初期化
    if (!AdobeTypekit.initialized) {
      AdobeTypekit.init()
    }

    if (typeof window !== "undefined") {
      this.handleWindowScroll = e => {
        this.doScroll(e)
      }
      this.handleWindowResize = e => {
        this.doResize()
      }
      window.addEventListener("scroll", this.handleWindowScroll, true)
      window.addEventListener("resize", this.handleWindowResize, true)
    }

    setTimeout(() => {
      this.doResize()
    }, 1)
  }

  // 親コンポーネントの参照を削除
  componentWillUnmount() {
    if (this.props.reference) {
      delete this.props.reference.layoutBasicInstance
    }
    if (typeof window !== "undefined") {
      window.removeEventListener("scroll", this.handleWindowScroll)
      window.removeEventListener("resize", this.handleWindowResize)
    }
  }

  doScroll(e) {
    let rightColumn = this.rightColumnNode.current

    if (!rightColumn) {
      return
    }

    let contentTop = rightColumn.getBoundingClientRect().top
    if (contentTop < 60) {
      this.ref.globalNavigationInstance.spHomeRedHeaderMode(true)
    } else {
      this.ref.globalNavigationInstance.spHomeRedHeaderMode(false)
    }
  }

  doResize() {
    const isSpMode =
      document.defaultView.getComputedStyle(this._spChecker.current, null)
        .display !== `none`

    this.setState(state => ({
      isSpMode: isSpMode,
    }))
  }

  render() {
    let slideStyle = {}
    if (this.state.isSpMode) {
      slideStyle = {
        height: document.documentElement.clientHeight + `px`,
      }
    }

    return (
      <div className={css.layoutHome} ref={this.node}>
        <div className={`onlySp`} ref={this._spChecker} />
        <GlobalNavigation
          home={true}
          reference={this.ref}
          lang={this.props.lang}
        />
        <div className={css.layoutHome__wrap}>
          <div
            className={css.layoutHome__left}
            ref={this._leftSlideColumn}
            style={slideStyle}
          >
            <div
              className={`only-sp`}
              style={{
                position: "absolute",
                bottom: "2vh",
                zIndex: 2,
                textAlign: "center",
                width: "100%",
              }}
            >
              <ScrollDown to={`#TopContent`} />
            </div>
            {this.props.children[0]}
          </div>
          <div className={css.layoutHome__right} ref={this.rightColumnNode}>
            <div className={css.layoutHome__right__content}>
              {this.props.children[1]}
            </div>
            {this.props.lang === "en" ? (
              <GlobalFooter>
                Project Subsidized by the Japan Ministry of Health, Labor and
                Welfare 2019
              </GlobalFooter>
            ) : (
              <GlobalFooter>令和元年度 厚生労働省補助事業</GlobalFooter>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default LayoutHome
